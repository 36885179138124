<!-- 组织架构-部门架构 -->
<template>
    <div >
        <div class="content-main">
            <div class="filter-wrap">
                <expand-filter
                    :close-width="170"
                    :formData="formData"
                    marginBottom="0px"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                >
                </expand-filter>
                <div class="button-line" v-hasPermi="['organ:add']"></div>
                <div >
                    <el-button v-hasPermi="['organ:add']" type="primary" @click="handleAdd">添加</el-button>
                </div>
            </div>

            <table-data
                v-loading="loadingTable"
                ref="table"
                :config="table_config"
                :tableData="table_data"
            >
                <template v-slot:nameHeader="slotData">
                    <div class="name-header-slot">
                        <el-tooltip class="item" effect="dark" :content="contentText" placement="top">
                            <el-button
                                type="text"
                                @click="handleClick"
                                class="expand-all"
                                :class="{active: table_config.isExpandAll}"
                            >
                                <i class="el-icon-arrow-down" />
                            </el-button>
                        </el-tooltip>    
                        <span style="white-space: nowrap;">组织名称</span>
                    </div>
                </template>
                <template v-slot:nameContent="slotData">{{ slotData.data.name }}</template>
                <template v-slot:operation="slotData">
                    <el-button
                        type="text"
                        v-if="isHaveOrganEdit"
                        @click="edit(slotData.data)"
                    >编辑</el-button>
                    <el-button
                        type="text"
                        v-if="isHaveOrganAddChild"
                        @click="addc(slotData.data)"
                        v-show="slotData.data.organType !== '4'"
                    >添加下级</el-button>
                    <el-button
                        type="text"
                        v-if="isHaveOrganDelete"
                        @click="del(slotData.data)"
                    >删除</el-button>
                </template>
            </table-data>
            <dialog-wrapper
                :dialogObj="dialogObj"
                @handleClose="handleClose"
            >
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="94px"
                    class="common-form"
					label-position="right"
                >
                    <el-form-item
                        label="组织名称"
                        prop="name"
                    >
                        <el-input
                            v-model="ruleForm.name"
                            placeholder="组织名称"
                            show-word-limit
                            maxlength="20"
							class="input-width-medium"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="上级组织">
                        <select-tree
                            class="input-width-medium"
                            ref="treeRef"
                            :tree-option="treeOption"
                            :tree-show-data="treeShowData"
                            :rule-form="ruleForm"
                            @treeClick="treeClick"
                            @clearTree="clearTree"
                        />
                    </el-form-item>
                    <el-form-item
                        label="组织类型"
                        prop="organType"
                    >
                        <template v-if="ruleForm.parentOrg !== ''">
                            <!--显示部门，和全部层级-->
                            <el-radio
                                v-for="(item, index) of [
                                        {label: '部门', value: '1'},
                                    ]"
                                :key="index"
                                v-model="ruleForm.organType"
                                :disabled="organTypeDisable"
                                :label="item.value"
                                @change="radioChange(item)"
                            >{{ item.label }}
                            </el-radio>
                        </template>
                        <template v-else>
                            <!--只显示部门，和层级第一个-->
                            <el-radio
                                v-for="(item, index) of [
                                        {label: '部门', value: '1'}
                                    ]"
                                :key="index"
                                v-model="ruleForm.organType"
                                :disabled="organTypeDisable"
                                :label="item.value"
                                @change="radioChange(item)"
                            >{{ item.label }}
                            </el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item
                        label="组织描述"
                        prop="remark"
                    >
                        <el-input
                            class="input-width-medium textarea"
                            type="textarea"
                            v-model="ruleForm.remark"
                            rows="10"
                            placeholder="请输入组织描述"
                            show-word-limit
                            maxlength="50"
                        ></el-input>
                    </el-form-item>
                    <div style="text-align: right;width:546px;">
                        <el-button
                            style="margin-left: 24px"
                            @click="handleClose"
                        >取消</el-button>
                        <el-button
                            type="primary"
                            @click="submitForm"
                        >确定</el-button>
                    </div>
                </el-form>
            </dialog-wrapper>
        </div>
    </div>
</template>

<script>
import {
    DialogWrapper,
} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableDataLine";
import selectTree from "./Sub/selectTree";
import ExpandFilter from "./Sub/ExpandFilter";
import {hasPermission} from "@/libs/utils.js";

import {mapState} from "vuex";
import onResize from "@/mixins/onResize";

const organType = [
    {label: "部门", value: "1"},
    {label: "级部", value: "2"},
    {label: "年级", value: "3"},
    {label: "班级", value: "4"},
];
export default {
    name: "SystemOrganizationalStructureScrollWrapper",
    mixins: [onResize],
    components: {
        selectTree,
        ExpandFilter,
        TableData,
        DialogWrapper,
    },
    data() {
        return {
            loadingTable: true,
            listQuery: {
                id: "",
                name: "",
                schoolId: "",
                organTypeList: ['1','5']
            },
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "组织名称",
                        key: "name",
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["organ:list"],
                    },
                ],
                btnFormType: true
            },

            // 表格配置
            table_config: {
                thead: [
                    {
                        type: 'setHeader',
                        slotName: 'nameHeader',
                        slotName1: 'nameContent',
                        label: "组织名称",
                        prop: "name",
                        align: "left",
                        labelWidth: "200",
                        className:"target-name",
                        fixed: false
                    },
                    {
                        label: "组织类型",
                        prop: "organTypeName",
                        type: "function",
                        align: 'center',
                        callBack: (row, prop) => {
                            return {
                                1: "部门",
                                2: "级部",
                                3: "年级",
                                4: "班级",
                            }[row.organType];
                        },
                    },
                    {
                        label: "更新时间",
                        labelDescription: "最后更新时间",
                        prop: "updateTime",
                        // labelWidth: "220",
                        align: 'center',
                        type: 'function',
                        callBack: (row) => {
                            return row.updateTime ? row.updateTime.slice(0, -3) : row.createTime.slice(0, -3)
                        }
                    },
                    {
                        label: "组织描述",
                        prop: "remark",
                        align: 'center',
                        minWidth: "200"
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: "260",
                        slotName: "operation",
                        className: 'text-spacing-reduction'
                    },
                ],
                check: false,
                rowkey: "id",
                isExpandAll: false,
                indent: 27,
                height: "",
            },
            // 表格数据
            table_data: [],

            // 弹出框配置
            dialogObj: {
                title: "添加组织",
                dialogVisible: false,
                width: "640px",
            },
            // 下拉树显示数据
            treeShowData: "",
            // 下拉树组件配置
            treeOption: {
                placeholder: "请选择上级部门",
                key: "parentOrg",
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                treeData: [], // 展示数据
                disabled: false, // 是否可编辑
				width:'452px'
            },
            // 类型是否可编辑
            organTypeDisable: false,
            // 弹窗表单数据
            ruleForm: {
                parentOrg: "", // 父组织id
                name: "",
                remark: "",
                id: "",
                organType: "1",
                greadStage: "",
                classType: "",
                classSubject: "",
                schoolId: "",
                parentList: "",
                joinTime: "",
            },
            // 校验规则
            rules: {
                joinTime: [
                    {
                        required: true,
                        message: "请选择年份",
                        trigger: "change",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "请输入组织名称",
                        trigger: "blur",
                    },
                ],
                organType: [
                    {
                        required: true,
                        message: "请选择组织类型",
                        trigger: "change",
                    },
                ],
                classType: [
                    {
                        required: true,
                        message: "请选择班级类型",
                        trigger: "change",
                    },
                ],
                classSubject: [
                    {
                        required: true,
                        message: "请选择教学科目",
                        trigger: "change",
                    },
                ],
                greadStage: [
                    {
                        required: true,
                        message: "请选择阶段",
                        trigger: "change",
                    },
                ],
            },
            list: [],
            types: [],
            parentOrganization: {
                id: "",
                name: "",
                schoolId: "",
                pageNum: 1
            },
            parentOrganizationData: [],
            open: false
        };
    },
    computed: {
        ...mapState({
            schoolStageList: (state) => state.schoolStageList,
        }),
        isHaveOrganEdit(){
            return hasPermission(["organ:edit"])
        },
        isHaveOrganAddChild(){
            return hasPermission(["organ:addChild"])
        },
        isHaveOrganDelete(){
            return hasPermission(["organ:delete"])
        },
        contentText() {
            return this.open ? '点击收起' : '点击展开'
        }
    },
    async created() {
        this.ruleForm.schoolId = this.listQuery.schoolId =
            this.$store.state.schoolId;
        this.parentOrganization.schoolId = this.$store.state.schoolId;
        await this.getList();
    },
    mounted() {
    },

    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.$refs.table) {
            this.$refs.table.$refs.multipleTable.doLayout();
        }
    },
    methods: {
        // 列表数据
        async getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            this.loadingTable = true;
            await this._fet(
                "/school/schoolOrgan/listByConditionNoAuth",
                this.listQuery,
            ).then((res) => {
                this.handleRes(res, () => {
                    this.list = res.data.data.list;


                     let dataList = this.formatRouterTree(
                        JSON.parse(JSON.stringify(res.data.data.list)),
                        "id",
                        "parentOrg",
                    );

                    this.dataFilter(dataList);
                    console.debug(JSON.parse(JSON.stringify(dataList)), "dataList");
                    this.table_data = dataList;
                    this.getParentOrganizationData();
                    this.loadingTable = false;
                });
            });
        },
        /**
         * @Description: 数据处理
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-7-15 11:10:09
         */
         dataFilter(data) {
            this.maxLevel = 1;
            this.getLevel(data, 1);
            console.debug(this.maxLevel, "最大层级");
            this.treeFilter2(data, 1);
            this.addEndData(data);
        },
        /**
         * @Description: 获取几级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-16 14:05:14
         */
         getLevel(data, level) {
            data.forEach((item) => {
                if (item.children && item.children.length > 0) {
                    if (this.maxLevel <= level + 1) {
                        this.maxLevel = level + 1;
                    }
                    this.getLevel(item.children, level + 1);
                }
            });
        },
        /**
         * @Description: 树形数据处理,给数据添加等级字段,是否展开字段,是否时最后一级字段
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-16 14:05:14
         */
         treeFilter2(data, level) {
            data.forEach((item) => {
                item.level = level;
                item.isOpen = false;
                item.isLast = false;
                item.isEnd = false;
                if (item.children && item.children.length > 0) {
                    this.treeFilter2(item.children, level + 1);
                }
            });
        },
        /**
         * @Description: 给从第二级开始的最后一级并且没有下级的行数据添加isEnd
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-18 15:01:15
         */
         addEndData(data) {
            data.forEach((item) => {
                if (item.children && item.children.length > 0) {
                    this.addEndData2(item.children);
                }
            });
        },
        /**
         * @Description: 给从第二级开始的最后一级并且没有下级的行数据添加isEnd
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-18 15:01:15
         */
        addEndData2(data) {
            let last = data[data.length - 1];
            if (last.children && last.children.length > 0) {
                this.addEndData2(last.children);
            } else {
                last.isEnd = true;
            }
        },
        async getParentOrganizationData () {
            await this._fet(
                "/school/schoolOrgan/listByCondition",
                this.listQuery,
            ).then((res) => {
                if (res.data.code == '200') {
                    this.parentOrganizationData = res.data.data.list;
                    this.treeOption.treeData = this.formatRouterTree(
                        JSON.parse(JSON.stringify(res.data.data.list)).filter(
                            (i) => i.organType == "1"
                        ),
                        "id",
                        "parentOrg",
                    );
                }
            })
        },
        clickBtn(type) {
            switch (type.item.fn) {
                case "submit": // 添加
                    this.dialogObj.title = "添加部门";
                    this.dialogObj.dialogVisible = true;
                    break;
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        if (item.key === "name") {
                            this.listQuery.name = item.value;
                        }
                    });
                    this.getList(1);
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                    });
                    this.listQuery.name = "";
                    this.listQuery.id = "";
                    this.getList(1);
                    break;
            }
        },
        handleAdd(){
            this.dialogObj.title = "添加部门";
            this.dialogObj.dialogVisible = true;
            this.organTypeDisable = true;
        },
        /**
         * @Description: 删除-是否允许删除
         * @Author: 闫乔
         * @Date: 2024-08-06 15:27:12
         * @param {*} data
         */        
        del(data) {
            // 校验是否允许删除
            this._fet("/school/schoolOrgan/allowDelete", {
                id: data.id
            }).then((ress) => {
                this.handleRes(ress, () => {
                    this.$confirm(
                        "是否确定删除？",
                        "提示",
                        {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        },
                    )
                        .then(() => {
                            this._fet("/school/schoolOrgan/delete", {
                                id: data.id,
                                name: data.name,
                            }).then((res) => {
                                this.handleRes(res, () => {
                                    this.getList();
                                    this.$message.success("已删除");
                                });
                            });
                        })
                        .catch(() => {});
                });
            });
        },
        // 编辑
        edit(data) {
            this.dialogObj.title = "编辑部门";
            this.organTypeDisable = true;
            let parent = this.parentOrganizationData.find((i) => i.id === data.parentOrg);
            if (parent) {
                this.treeShowData = parent.name; // 上级组织显示数据赋值
            }
            this.$nextTick(() => {
                Object.keys(this.ruleForm).forEach((key) => {
                    this.ruleForm[key] = data[key];
                });
            });
            this.dialogObj.dialogVisible = true;
        },
        // 类型切换，清空之前选中数据
        radioChange() {
            this.ruleForm.classType = "";
            this.ruleForm.greadStage = "";
            this.ruleForm.classSubject = "";
        },
        // 添加下级
        addc(data) {
            this.dialogObj.title = "添加下级";
            this.treeOption.disabled = true; // 上级下拉不可编辑
            this.treeShowData = data.name; // 上级组织显示名称
            this.dialogObj.dialogVisible = true;
            this.organTypeDisable = true; // 类型不可编辑
            this.$nextTick(() => {
                this.ruleForm.parentOrg = data.id;
                this.ruleForm.greadStage = data.greadStage;
                // 部门下只可添加部门
                if (data.organType === "1") {
                    this.ruleForm.organType = "1";
                }
            });
        },
        // 上级部门下拉清除
        clearTree() {
            this.organTypeDisable = true; // 类型可编辑
            this.ruleForm.organType = "1"; // 清空类型选中值
            this.ruleForm.parentOrg = "";
            this.ruleForm.greadStage = '';
            this.ruleForm.parentOrg = '';
            this.treeShowData = '';
            this.$refs.treeRef.$refs.elSelect.blur();
        },
        /**
         * @Description: 根据id获取祖先id
         * @Author: 闫乔
         * @Date: 2024-08-06 14:39:33
         * @param {*} arr
         * @param {*} id
         * @param {*} idKey
         * @param {*} parentKey
         * @param {*} ancestors
         */        
        findAncestors(arr, id, idKey = 'id', parentKey = 'parentOrg', ancestors) {
            if (!ancestors) {
                ancestors = [];
            }
            let current = arr.find(item => item[idKey] === id);

            if (current && current[parentKey]) {
                ancestors.push(current[parentKey]);
                this.findAncestors(arr, current[parentKey], idKey, parentKey, ancestors)
            }

            return ancestors;
        },
        // 上级部门下拉选中
        treeClick(data) {
            // 上级部门就是自己
            if (data.id === this.ruleForm.id) {
                return this.$message.warning("不允许选择本组织作为上级组织");
            }
            // 选择上级部门，如果选择其子级
            // 如果选择的节点存在父级并且是编辑状态
            if(data.parentOrg && this.ruleForm.id){
                let resultIds = this.findAncestors(this.list,data.id);
                if(resultIds.includes(this.ruleForm.id)){
                    return this.$message.warning("不允许选择该部门及下级组织");
                }
                
            }
            this.ruleForm.parentOrg = data.id;
            this.organTypeDisable = true; // 类型不可编辑
            this.ruleForm.greadStage = data.greadStage;
            this.ruleForm.parentOrg = data.id;
            this.treeShowData = data.name;
            this.$refs.treeRef.$refs.elSelect.blur();
            // 部门下只可添加部门
            if (data.organType == "1") {
                this.ruleForm.organType = "1";
            }
        },
        // 添加，编辑
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    console.log('this.ruleForm添加的数据',this.ruleForm);
                    // 存在上级,拼接parentList数据
                    if (this.ruleForm.parentOrg !== "") {
                        const plist = [];
                        for (
                            var i = this.ruleForm;
                            (i = this.list.find((it) => it.id == i.parentOrg));

                        ) {
                            plist.push(i.id);
                        }
                        this.ruleForm.parentList = plist.reverse().join();
                    } else {
                        // 不存在上级置空
                        this.ruleForm.parentList = "";
                    }
                    this._fet("/school/schoolOrgan/save", this.ruleForm).then(
                        (res) => {
                            this.handleRes(res, () => {
                                this.getList();
                                this.$message.success("操作成功");
                                this.handleClose();
                            });
                        },
                    );
                }
            });
        },

        // 弹窗关闭
        handleClose() {
            this.$refs.ruleForm.resetFields();
            this.ruleForm.id = "";
            this.ruleForm.parentOrg = ""; // 上级部门选中数据置空
            this.treeShowData = "";
            this.$refs.treeRef.$data.filterText = "";
            this.treeOption.disabled = false; // 上级部门可编辑
            this.organTypeDisable = true; // 类型可编辑
            this.dialogObj.dialogVisible = false; // 关闭弹窗
        },
        handleClick(isAss) {
            this.open = !this.open; 
            //this.$refs.ctab.handleExpand(isAss)
            this.handleExpand(isAss);
        },
        handleExpand(isAss) {
            // 此判断是否为展开状态或折叠状态--若展开折叠是同一个按钮则无需传参和加此if
            this.table_config.isExpandAll = !this.table_config.isExpandAll;
            // this.tableData为返回的二级数据data
            this.$nextTick(() => {
                this.forArr(this.list, this.table_config.isExpandAll);
            });
        },
        // 遍历
        forArr(arr, isExpand) {
            arr.forEach((i) => {
                // toggleRowExpansion见element文档
                this.$refs.table.$refs.multipleTable.toggleRowExpansion(
                    i,
                    isExpand,
                );
                if (i.children) {
                    this.forArr(i.children, isExpand);
                }
            });
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        formatRouterTree(data, key, parentKey) {
            let parents = [];
            let children = [];
            data.forEach((item, index) => {
                let find = data.find((i) => item[parentKey] === i[key]);
                if (find && item[parentKey] !== undefined) {
                    children.push(item);
                } else {
                    parents.push(item);
                }
            });
            dataToTree(parents, children, key, parentKey);

            function dataToTree(parents, children, key, parentKey) {
                parents.map((p) => {
                    children.map((c, i) => {
                        if (c[parentKey] === p[key]) {
                            let _c = JSON.parse(JSON.stringify(children));
                            _c.splice(i, 1);
                            dataToTree([c], _c, key, parentKey);
                            if (p.children) {
                                p.children.push(c);
                            } else {
                                p.children = [c];
                            }
                        }
                    });
                });
            }

            return parents;
        },
    }
};
</script>
<style lang="scss" scoped>
.content-wrapper {
    border-radius: 4px;
    margin-top: 0;
}
.filter-wrap {
    display: flex;
    text-align: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}


.table-data {
    border-radius: 4px;
    overflow: hidden;
}
::v-deep .el-table {
    .el-table__row .el-table__cell:first-child .cell {
        padding-left: 62px;
    }

    [class*=el-table__row--level] {
        .el-table__expand-icon {
            margin-right: 4px;

            &:hover .el-icon-arrow-right {
                background-color: #3c7fff;
            }
        }

        .el-table__expand-icon .el-icon-arrow-right {
            transform: rotate(90deg);
            color: #FFFFFF;
            font-size: 13px;
            width: 16px;
            height: 16px;
            line-height: 17px;
            text-align: center;
            background-color: #bfbfbf;
            border-radius: 50%;
            transition: rotate 0.2s ease-in;
        }

        .el-table__expand-icon--expanded .el-icon-arrow-right {
            transform: rotate(-180deg) !important;
            background-color: #3c7fff;
        }

        .el-table__placeholder {
            width: 0;
        }
    }
}

.name-header-slot {
    display: flex;
    align-items: center;

    .expand-all {
        display: flex;
        align-items: center;
        margin-right: 16px;
        height: 23px;
        padding: 0;

        .el-icon-arrow-down {
            width: 16px;
            height: 16px;
            line-height: 18px;
            text-align: center;
            font-size: 13px;
            border-radius: 50%;
            color: #FFFFFF;
            background-color: #bfbfbf;
            transition: rotate 0.2s ease-in;
        }

        span {
            margin-left: 5px;
            font-size: 14px;
            color: #595959;
            font-family: Microsoft YaHei;
        }

        &:hover {
            span {
                color: #3c7fff;
            }

            .el-icon-arrow-down {
                color: #ffffff;
                background-color: #3c7fff;
            }
        }
    }

    .active {
        .el-icon-arrow-down {
            transform: rotate(-180deg);
            background-color: #3c7fff;
        }

        span {
            color: #3c7fff;
        }
    }
}

.textarea {
    /deep/ .el-textarea__inner {
        color: #606266;
        font-size: 14px;
    }

}
</style>
